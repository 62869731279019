import styles from './variables.module.scss'

type Styles = {
  headerHeight: string
  leftSidebarWidth: string
  baseLeftPanelWidth: string

  zIndexPanel: string
  zIndexHeaderMenu: string
}

export const Styles = styles as Styles
