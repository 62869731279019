import React, { useCallback } from 'react'
import { PanelType, useLeftSidePanelContext } from '../../context/LeftSidePanelContextProvider'
import { ButtonIcon } from './ButtonIcon'
import styles from './LeftSidebar.module.scss'
import { PanelResolver } from './PanelResolver'

export const LeftSidebar = () => {
  const { panel, openPanel, closePanel } = useLeftSidePanelContext()

  const onButtonClick = useCallback(
    (target: PanelType) => () => {
      if (target === panel) {
        closePanel()
      } else {
        openPanel(target)
      }
    },
    [panel, openPanel],
  )

  return (
    <>
      <div className={styles.container}>
        <div className={styles.controls}>
          <ButtonIcon icon="DashboardIcon" isActive={panel === PanelType.Dashboard} onClick={closePanel} disabled />
          <ButtonIcon
            icon="FilterIcon"
            isActive={panel === PanelType.Filters}
            onClick={onButtonClick(PanelType.Filters)}
          />
          <ButtonIcon
            icon="NewspaperIcon"
            isActive={panel === PanelType.IncidentsFeed}
            onClick={onButtonClick(PanelType.IncidentsFeed)}
          />
          <ButtonIcon icon="DocumentMapIcon" isActive={false} onClick={closePanel} disabled />
        </div>
      </div>
      <PanelResolver panel={panel} closePanel={closePanel} />
    </>
  )
}
