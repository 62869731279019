import React, { useState } from 'react'
import { MapManager } from '../MapView/MapManager'
import { useMapbox } from '../MapView/useMapbox'

interface MapContextData {
  map: MapManager | null
  mapRefEl: React.MutableRefObject<HTMLDivElement | null>
  moveRight: boolean
  moveMapAside(aside: boolean): void
}

const MapContext = React.createContext<MapContextData>({
  map: null,
  mapRefEl: React.createRef(),
  moveRight: false,
  moveMapAside: () => null,
})

export const useMapContext = () => React.useContext(MapContext)

export const MapContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { ref, map } = useMapbox()
  const [moveRight, moveMapAside] = useState(false)

  return (
    <MapContext.Provider
      value={{
        map,
        mapRefEl: ref,
        moveRight,
        moveMapAside,
      }}
    >
      {children}
    </MapContext.Provider>
  )
}
