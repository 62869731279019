import { pick, sum } from 'lodash'
import { LatLng } from './MapDataDrawer.custom'

/**
 * Not sure it works correctly
 * README: https://stackoverflow.com/questions/68886098/how-can-i-get-the-center-coordinate-from-the-given-coordinates
 */
export const findLocationsCenter = (
  locations: LatLng[],
): { center: Partial<LatLng>; bounds: { ne: LatLng; sw: LatLng } } => {
  const getMiddle = (prop: keyof LatLng, locations: LatLng[]) => {
    const isLongitude = prop === 'longitude'
    let values = locations.map((m) => {
      if (isLongitude && m[prop] < 0) {
        return m[prop] + 360
      }
      return m[prop]
    })
    let result = sum(values) / values.length
    if (isLongitude && result > 180) {
      result -= 360
    }
    return result
  }

  const getBounds = (): { ne: LatLng; sw: LatLng } => {
    let ne: LatLng = pick(locations[0], ['latitude', 'longitude'])
    let sw: LatLng = pick(locations[0], ['latitude', 'longitude'])
    locations.forEach((spot) => {
      if (spot.latitude > ne.latitude) {
        ne.latitude = Number(spot.latitude)
      }
      if (spot.longitude > ne.longitude) {
        ne.longitude = Number(spot.longitude)
      }
      if (spot.latitude < sw.latitude) {
        //sw = south west
        sw.latitude = Number(spot.latitude)
      }
      if (spot.longitude < sw.longitude) {
        sw.longitude = Number(spot.longitude)
      }
    })

    return { ne, sw }
  }

  const center = {
    latitude: getMiddle('latitude', locations),
    longitude: getMiddle('longitude', locations),
  }

  return {
    center,
    bounds: getBounds(),
  }
}
