/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GeoJSONSource, LngLatLike, Marker } from 'mapbox-gl'
import { Colors } from '@/styles/colors'
import { montserratFontFamily } from '@/styles/fonts'
import { MapManager } from '../MapManager'
import { LatLng } from './MapDataDrawer.custom'
import { IncidentFeatureItem, RenderIncidentsItem } from './MapDataDrawer.types'

export type ClusterTypes = {
  type1: number
  type2: number
  type3: number
  type4: number
  type5: number
  type6: number
  type7: number
  type8: number
  type9: number
}
export type ClusterData = {
  cluster: boolean
  cluster_id: number
  point_count: number
  point_count_abbreviated: string
} & ClusterTypes

type FeatureItem = Partial<{ id: number } & ClusterData>
type FeatureItemProps = { properties: FeatureItem }

//  Cluster Property Aggregation with Supercluster - https://gist.github.com/ryanbaumann/01b2c7fc0ddb7b27f6a72217bd1461ad

export interface ClusterDonutMarkersProps {
  mapManager: MapManager
  source: string
  layer: string
  onClusterClick: (clusterData: ClusterData, config: { ids: RenderIncidentsItem['id'][]; locations: LatLng[] }) => void
}
/**
 * README:
 * Example: https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/
 */
export class ClusterDonutMarkers {
  private markers: Record<string, Marker> = {}
  private markersOnScreen: Record<string, Marker> = {}
  private map: ReturnType<MapManager['getMap']> // ClusterDonutMarkersProps['map']
  private mapManager: MapManager
  private source: ClusterDonutMarkersProps['source']
  private layer: ClusterDonutMarkersProps['layer']
  private jsonSource: GeoJSONSource
  private onClusterClick: ClusterDonutMarkersProps['onClusterClick']

  constructor({ mapManager, source, layer, onClusterClick }: ClusterDonutMarkersProps) {
    this.mapManager = mapManager
    this.map = mapManager.getMap()
    this.source = source
    this.layer = layer
    this.jsonSource = this.map?.getSource(this.source) as GeoJSONSource
    this.onClusterClick = onClusterClick
  }

  render = ({ opacity, colors }: { colors: string[]; opacity: number }) => {
    const newMarkers: Record<string, Marker> = {}
    type Feature = GeoJSON.Feature<
      // @ts-ignore
      { coordinates: LngLatLike },
      { cluster_id: string; cluster: boolean; point_count: number; point_count_abbreviated: number } & ClusterTypes
    >
    const features: Feature[] = this.map?.querySourceFeatures(this.source, { sourceLayer: this.layer }) as any

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {
      const coords = feature.geometry.coordinates // as PointLike
      const props: ClusterTypes & ClusterData = feature.properties as any

      if (!props.cluster) {
        continue
      }

      const id = props.cluster_id
      let marker = this.markers[id]
      if (!marker) {
        const el = this.create(props as any, { colors, opacity })
        marker = this.markers[id] = new Marker({ element: el }).setLngLat(coords)

        // Navigate into cluster
        marker.getElement().addEventListener('click', async (e) => {
          const items = await this.getClusterItems(props)
          const ids: RenderIncidentsItem['id'][] = []
          const locations: LatLng[] = []
          items.forEach((item) => {
            ids.push(item.id!)
            locations.push({
              latitude: item.properties.latitude,
              longitude: item.properties.longitude,
            })
          })

          this.onClusterClick(props, { ids, locations })
        })
      }
      newMarkers[id] = marker

      if (!this.markersOnScreen[id]) {
        try {
          marker.addTo(this.map!)
        } catch (err) {
          console.error(err)
        }
      }
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) {
        this.markersOnScreen[id].remove()
      }
    }

    this.markersOnScreen = newMarkers
  }

  getClusterZoom = (clusterId: number) => {
    return new Promise<number>((resolve) => {
      this.jsonSource.getClusterExpansionZoom(clusterId, (err, result) => {
        resolve(result as number)
      })
    })
  }

  private getClusterItems = async ({ cluster_id, point_count }: Pick<ClusterData, 'cluster_id' | 'point_count'>) => {
    if (!point_count) {
      return getClusterItems(cluster_id, this.jsonSource)
    }
    return new Promise<IncidentFeatureItem[]>((resolve) => {
      this.jsonSource.getClusterLeaves(cluster_id, point_count, 0, (err, items) => {
        resolve(items as IncidentFeatureItem[])
      })
    })
  }

  // code for creating an SVG donut chart from feature properties
  private create = (
    props: ClusterTypes & ClusterData,
    { colors, opacity = 1 }: { colors: string[]; opacity?: number },
  ) => {
    const offsets = []
    const typeCounts = Object.keys(props).reduce((acc, key: keyof ClusterTypes | string) => {
      if (key.startsWith('type') && Number.isInteger(parseInt(key.replace('type', '')))) {
        acc.push(props[key as keyof ClusterTypes])
      }
      return acc
    }, [] as number[])
    const pointCounts = String(props.point_count_abbreviated).replace(',', '.')
    let total = 0
    for (const count of typeCounts) {
      offsets.push(total)
      total += count
    }
    const fontSize = total >= 1000 ? 16 : total >= 100 ? 15 : total >= 10 ? 14 : 12
    const r = total >= 1000 ? 32 : total >= 100 ? 26 : total >= 10 ? 18 : 16
    const r0 = Math.round(r * 0.6)
    const w = r * 2

    let html = `<div opacity="${opacity}" style="cursor: pointer;">
        <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block;" opacity="${opacity}">`

    for (let i = 0; i < typeCounts.length; i++) {
      html += this.donutSegment(offsets[i] / total, (offsets[i] + typeCounts[i]) / total, r, r0, colors[i])
    }
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="${Colors.Neutral_80}" opacity="${opacity}" />
            <text dominant-baseline="central" transform="translate(${r}, ${r})" font-family="${montserratFontFamily}" fill="${Colors.White}" opacity="${opacity}">
                ${pointCounts}
            </text>
        </svg>
      </div>`

    const el = document.createElement('div')
    el.innerHTML = html

    return el.firstChild as HTMLElement
  }

  private donutSegment = (start: number, end: number, r: number, r0: number, color: string) => {
    if (end - start === 1) end -= 0.00001
    const a0 = 2 * Math.PI * (start - 0.25)
    const a1 = 2 * Math.PI * (end - 0.25)
    const x0 = Math.cos(a0)
    const y0 = Math.sin(a0)
    const x1 = Math.cos(a1)
    const y1 = Math.sin(a1)
    const largeArc = end - start > 0.5 ? 1 : 0

    // draw an SVG path
    return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
      r + r * y0
    } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${
      r + r0 * x1
    } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}" fill="${color}" />`
  }
}

async function getClusterItems(clusterId: number, jsonSource: GeoJSONSource): Promise<IncidentFeatureItem[]> {
  // add recursion, because some items are clusters
  const getItemsInCluster = async (clusterId: number) => {
    return new Promise<IncidentFeatureItem[]>((resolve, reject) => {
      jsonSource.getClusterChildren(clusterId, (err, items) => {
        if (err) {
          reject([])
        } else {
          const clusterItems = (items as FeatureItemProps[]).reduce(async (previousPromise, item) => {
            let acc = await previousPromise
            if (item.properties.cluster_id) {
              const _items = await getItemsInCluster(item.properties.cluster_id)
              acc.push(..._items)
            } else {
              acc.push(item as IncidentFeatureItem)
            }
            return acc
          }, Promise.resolve<IncidentFeatureItem[]>([]))

          resolve(clusterItems)
        }
      })
    })
  }

  const clusterItems = await getItemsInCluster(clusterId)

  return clusterItems as IncidentFeatureItem[]
}
