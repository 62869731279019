import { useCallback, useState } from 'react'
import { PanelType } from './panel.types'

export { PanelType }

export const usePanel = () => {
  const [panel, setPanel] = useState<PanelType | null>(null)

  const openPanel = useCallback((panel: PanelType) => setPanel(panel), [])
  const closePanel = useCallback(() => setPanel(null), [])

  return {
    panel,
    openPanel,
    closePanel,
  }
}
