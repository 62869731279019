import React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Styles } from '@/styles/styles'
import { useMapContext } from '../context/MapContextProvider'
import './MapView.scss'
import { MAPBOX_CONTAINER_ID, MAPBOX_SEARCH_CONTAINER_ID } from './constants'

const LEFT = `calc(${Styles.leftSidebarWidth} + ${Styles.baseLeftPanelWidth})`
const WIDTH = `calc(100% - ${LEFT})`
const POSITION = { width: WIDTH, left: LEFT, right: 0 }

const DEFAULT_POSITION = {
  width: `calc(100% - ${Styles.leftSidebarWidth})`,
  left: Styles.leftSidebarWidth,
  right: 0,
}

export const MapBoxView = () => {
  const { mapRefEl, moveRight } = useMapContext()

  return (
    <>
      <div
        id={MAPBOX_CONTAINER_ID}
        ref={mapRefEl}
        style={{ height: 'inherit', ...(moveRight ? POSITION : DEFAULT_POSITION) }}
      />
      <div id={MAPBOX_SEARCH_CONTAINER_ID} />
    </>
  )
}
