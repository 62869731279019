import { useEffect, useMemo } from 'react'
import { QueryClient, QueryObserver, useQueryClient } from '@tanstack/react-query'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'
import { Storage } from '@/core/services/Storage'
import { Env } from '@/utils/env'
import { QUERY_KEY_ASSETS } from './useAssetsData'
import { QUERY_KEY_INCIDENT_LOCATIONS } from './useIncidentLocationsData'
import { QUERY_KEY_INCIDENT_TYPES } from './useIncidentTypesData'
import { QUERY_KEY_INCIDENTS } from './useIncidentsData'
import { QUERY_KEY_SEVERITY_LEVELS } from './useSeverityLevelsData'

const storage = new Storage('map-events-data')

const __appReactQuery = {
  storage,
  client: { clear: () => null } as any,
  clear: () => {
    __appReactQuery.client.clear()
    __appReactQuery.storage.clear()
  },
  _setClient: (client: QueryClient) => (__appReactQuery.client = client),
}
if (Env.isDev() && typeof window !== 'undefined') {
  Object.assign(window, { __appReactQuery })
}

const setLocallyCachedData = (queryKey: string[], data: any, onError?: (e: unknown) => void) =>
  storage.setValue(JSON.stringify(queryKey), data, onError)

const getLocallyCachedData = (queryKey: string[]): any => storage.getValue(JSON.stringify(queryKey))

const getDate = () => new Date().getDate()

const useQueryKeyCacheWorker = (queryKey: string[]) => {
  const client = useQueryClient()
  const observer = useMemo(() => {
    const observer = new QueryObserver(client, { queryKey })
    return observer
  }, [client, queryKey])

  useEffect(() => {
    if (Env.isDev() && typeof window !== 'undefined') {
      __appReactQuery._setClient(client)

      if (!observer.hasListeners()) {
        observer.subscribe((query) => {
          const onError = () => {
            if (Array.isArray(query.data)) {
              setLocallyCachedData(queryKey, query.data.slice(0, 1000))
            }
          }
          query.isSuccess && setLocallyCachedData(queryKey, query.data, onError)
        })
      }

      const data = getLocallyCachedData(queryKey)
      if (data) {
        client.cancelQueries({ queryKey })
        client.setQueryData(queryKey, data)
      }
    }
  }, [client, observer])
}

/**
 * Using to force cache in LS, to avoid do each time requests to BQ data
 */
export const useDevModeLocalCaching = () => {
  const { orgId } = useCurrentUser()

  useQueryKeyCacheWorker([QUERY_KEY_ASSETS, orgId])
  useQueryKeyCacheWorker([QUERY_KEY_INCIDENT_TYPES, orgId])
  useQueryKeyCacheWorker([QUERY_KEY_INCIDENTS, orgId])
  useQueryKeyCacheWorker([QUERY_KEY_SEVERITY_LEVELS, orgId])
  useQueryKeyCacheWorker([QUERY_KEY_INCIDENT_LOCATIONS, orgId])

  useEffect(() => {
    const date = getDate()
    if (String(storage.getValue('date')) !== String(date)) {
      storage.clear()
      storage.setValue('date', date)
    }
  }, [])
}
