import React, { useState } from 'react'
import { Loader } from '@/components/common/Loader'
import styles from './TargetCard.module.scss'
import { TargetDetails, type TargetDetailsProps } from './TargetDetails'

interface TargetCardProps {
  className?: string
  details: TargetDetailsProps
  imageSrc?: string
  description: string
  isImageLoading?: boolean
  link?: string
}

const isGoogleDriveImage = (imageSrc = '') => !!imageSrc?.match('drive.google')
const getGoogleFileId = (imageSrc: string) => imageSrc.split('/file/d/')[1].split('/')[0]
const getGoogleDriveThumbnail = (imageSrc: string) =>
  `https://drive.google.com/thumbnail?id=${getGoogleFileId(imageSrc)}&sz=w420`

const TargetImage: React.FC<Pick<TargetCardProps, 'imageSrc' | 'isImageLoading'>> = ({ imageSrc, isImageLoading }) => {
  if (!isImageLoading && !imageSrc) {
    return null
  }

  const googleImageLink = imageSrc && isGoogleDriveImage(imageSrc) ? getGoogleDriveThumbnail(imageSrc) : null

  return (
    <div key={imageSrc} className={styles.image}>
      {imageSrc && !googleImageLink && <img src={imageSrc} />}
      {googleImageLink && <img src={googleImageLink} />}
      {isImageLoading && <Loader />}
    </div>
  )
}

export const TargetCard: React.FC<TargetCardProps> = ({ details, isImageLoading, imageSrc, description, link }) => {
  return (
    <div className={styles.container}>
      <TargetDetails {...details} />
      <TargetImage imageSrc={imageSrc} isImageLoading={isImageLoading} />
      <div className={styles.description}>{description}</div>
      {link && (
        <div className={styles.link}>
          <a href={link} target="_blank" rel="noreferrer">
            See Details
          </a>
        </div>
      )}
    </div>
  )
}
