'use client'

import React from 'react'
import { BottomPageControlsWrapper } from '@/components/layout/BottomPageControlsWrapper'
import { useCurrentOrganization } from '@/core/hooks/useCurrentOrganization'
import { MapBoxView } from './MapView'
import styles from './SituationalAwarenessPage.module.scss'
import { LeftSidebar } from './components/LeftSidebar'
import { LoadingDataIndicator } from './components/LoadingDataIndicator'
import { LazyMapDemoControls } from './components/MapDemoControls'
import { RightSidebarDrawer } from './components/RightSidebarDrawer'
import { CopilotFilterControl } from './components/panels/CopilotFilterControl'
import { ContextsProviders } from './context/ContextsProviders'
import { useDevModeLocalCaching } from './hooks/useDevModeLocalCaching'

export const SituationalAwarenessPage = () => {
  useDevModeLocalCaching()

  const { organizationModel } = useCurrentOrganization()

  return (
    <div className={styles.container}>
      <ContextsProviders>
        <MapBoxView /> {/* README: used via "mapRefEl" */}
        <LazyMapDemoControls className={styles.demoPanel} />
        <LeftSidebar />
        <RightSidebarDrawer />
        <BottomPageControlsWrapper>
          {organizationModel?.isCopilotAvailable() && <CopilotFilterControl />}
        </BottomPageControlsWrapper>
        <LoadingDataIndicator />
      </ContextsProviders>
    </div>
  )
}
