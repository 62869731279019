import { useQuery } from '@tanstack/react-query'
import { Api } from '@/core/api'
import { IncidentItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'

export const QUERY_KEY_LINK_PREVIEW_IMAGE = 'link-preview-image'

// TODO: Should be common API hook
export const useLinkPreviewImageData = ({
  link,
  enabled,
}: {
  enabled?: boolean
  link: IncidentItem['Hyperlink'] | undefined
}) => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_LINK_PREVIEW_IMAGE, link],
    queryFn: () => Api.getLinkPreviewImage({ link: link! }),
    enabled: typeof enabled !== 'undefined' ? enabled : !!link,
  })

  return { data, ...query }
}
