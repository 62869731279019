import React, { useEffect, useState } from 'react'
import { PanelType } from '../../context/LeftSidePanelContextProvider'
import { LazyFiltersPanel, LazyIncidentsFeedPanel } from '../panels'

interface PanelResolverProps {
  panel: PanelType | null
  closePanel(): void
}

export const PanelResolver: React.FC<PanelResolverProps> = ({ panel, closePanel }) => {
  const [loaded, setLoaded] = useState<Record<PanelType, boolean>>({
    [PanelType.Dashboard]: false,
    [PanelType.Filters]: false,
    [PanelType.IncidentsFeed]: false,
    [PanelType.ActiveIncidents]: false,
  })

  useEffect(() => {
    if (panel) {
      setLoaded((state) => ({ ...state, [panel]: true }))
    }
  }, [panel])

  if (!panel) {
    return null
  }

  switch (panel) {
    case PanelType.Filters:
      return <LazyFiltersPanel closePanel={closePanel} animated={loaded[PanelType.Filters]} />
    case PanelType.IncidentsFeed:
      return <LazyIncidentsFeedPanel closePanel={closePanel} animated={loaded[PanelType.IncidentsFeed]} />
    default:
      return null
  }
}
