import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { find, isEmpty, uniqBy } from 'lodash'
import { AssetItem, IncidentItem, IncidentTypeItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { MapDataDrawer } from '../MapView/MapDataDrawer'
import { RenderAssetsItem, RenderIncidentsItem } from '../MapView/MapDataDrawer/MapDataDrawer.types'
import { filterIncidents } from '../components/MapDemoControls/utils/filterIncidents'
import { TargetAssetCard } from '../components/cards/TargetAssetCard'
import { TargetIncidentCard } from '../components/cards/TargetIncidentCard'
import { useAssetsData } from '../hooks/useAssetsData'
import { useIncidentTypesData } from '../hooks/useIncidentTypesData'
import { useIncidentsByQueryData } from '../hooks/useIncidentsByQueryData'
import { useIncidentsData } from '../hooks/useIncidentsData'
import { useFiltersContext } from './FiltersContextProvider'
import { PanelType, useLeftSidePanelContext } from './LeftSidePanelContextProvider'
import { useMapContext } from './MapContextProvider'

export type ActiveTarget =
  | React.ComponentProps<typeof TargetAssetCard>['target']
  | React.ComponentProps<typeof TargetIncidentCard>['target']

interface MapContextData {
  isLoadingData: boolean
  incidentsData: IncidentItem[]
  incidentTypesData: IncidentTypeItem[]
  activeTarget: ActiveTarget | null
  setActiveTarget(target: MapContextData['activeTarget']): void
  activeIncidents: IncidentItem[] | null
  setActiveIncidents(items: MapContextData['activeIncidents']): void
  renderIncidents(incidents: IncidentItem[], args?: { withCentrify?: boolean }): void
}

const MapEventsContext = React.createContext<MapContextData>({
  isLoadingData: false,
  incidentsData: [],
  incidentTypesData: [],
  renderIncidents: () => null,
  activeTarget: null,
  setActiveTarget: () => null,
  activeIncidents: null,
  setActiveIncidents: () => null,
})

export const useMapEventsContext = () => React.useContext(MapEventsContext)

/**
 *  Load events, rendering and interaction
 */
export const MapEventsContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [activeTarget, setActiveTarget] = useState<MapContextData['activeTarget']>(null)
  const [activeIncidents, setActiveIncidents] = useState<IncidentItem[] | null>(null)
  // const [selectedAssets, setSelectedAssets] = useState<RenderAssetsItem[]>([])
  const selectedAssets = useRef<RenderAssetsItem[]>([])
  const { map } = useMapContext()
  const { hasFilters, filters, filterQuery, queryId } = useFiltersContext()
  const { openPanel } = useLeftSidePanelContext()

  const setSelectedAssets = (assets: RenderAssetsItem[]) => (selectedAssets.current = assets)
  const getSelectedAssets = () => selectedAssets.current

  const { assetsData } = useAssetsData()
  const { incidentsData: pureIncidentsData, isFetching: isFetchingPureIncidents } = useIncidentsData({
    enabled: !filterQuery,
  })
  const { incidentsData: queryIncidentsData, isFetching: isFetchingQueryIncidents } = useIncidentsByQueryData({
    enabled: !!filterQuery,
    question: filterQuery,
    queryId,
  })
  const { incidentTypesData } = useIncidentTypesData()

  const incidentsData = useMemo(() => {
    return filterQuery ? queryIncidentsData : pureIncidentsData
  }, [filterQuery, pureIncidentsData, queryIncidentsData])

  const mapDrawer = useMemo(() => (map ? new MapDataDrawer().setMap(map) : new MapDataDrawer()), [map])

  const onAssetClick = useCallback(
    (id: AssetItem['AssetID']) => {
      const item = find(assetsData!, { AssetID: id })
      if (item) {
        setActiveTarget(item)
      }
    },
    [assetsData],
  )

  const onIncidentClick = useCallback(
    (id: IncidentItem['IncidentID']) => {
      const item = find(incidentsData!, { IncidentID: id })
      if (item) {
        setActiveTarget(item)
      }
    },
    [incidentsData],
  )

  const onIncidentsClick = useCallback(
    (ids: IncidentItem['IncidentID'][]) => {
      const items = incidentsData.filter((item) => ids.includes(item.IncidentID))
      if (items.length) {
        setActiveIncidents(items)
      }
    },
    [incidentsData],
  )

  const renderIncidents: MapContextData['renderIncidents'] = useCallback(
    (incidents: IncidentItem[], args) => {
      if (mapDrawer.hasMap()) {
        const items: RenderIncidentsItem[] =
          incidents.map((item) => ({
            id: item.IncidentID,
            title: item.Title,
            description: item.Title,
            incidentTypeId: item.IncidentTypeID,
            latitude: item.Latitude,
            longitude: item.Longitude,
          })) || []

        const onItemClick = onIncidentClick.bind(null)
        // README: Temporary disabled
        // const withCentrify = typeof args?.withCentrify !== 'undefined' ? args.withCentrify : hasFilters || !!filterQuery
        mapDrawer.renderIncidentDonutClusters({
          items,
          withCentrify: false,
          onClick: async (item, data) => {
            const ids = !isEmpty(data?.ids) ? data?.ids : []
            if (ids.length > 1) {
              onIncidentsClick(ids)
              openPanel(PanelType.IncidentsFeed)
            } else {
              onItemClick(item.id as number)
            }
          },
          onClusterClick: async (clusterData, { ids, locations }) => {
            onIncidentsClick(ids)
            openPanel(PanelType.IncidentsFeed)
          },
        })
      }
    },
    [mapDrawer, mapDrawer.hasMap(), hasFilters, filterQuery, onIncidentClick],
  )

  useEffect(() => {
    const toRenderAssetsItem = (item: AssetItem): RenderAssetsItem => ({
      id: item.AssetID,
      title: item.AssetName,
      description: item.AssetDescription,
      impactRadius: item.ImpactRadius,
      latitude: item.Latitude,
      longitude: item.Longitude,
    })
    if (mapDrawer.hasMap() && !isEmpty(assetsData)) {
      const items: RenderAssetsItem[] = assetsData?.map((item) => toRenderAssetsItem(item)) || []

      mapDrawer.renderAssets({
        items,
        onClick: (item, { metaKey }) => {
          if (metaKey) {
            const asset = assetsData?.find(({ AssetID }) => AssetID === item.id)
            const selectedAssets = getSelectedAssets()
            const isAlreadySelected = selectedAssets.find((i) => i.id === item.id)
            let updatedSelectedAssets = asset
              ? uniqBy([...getSelectedAssets(), toRenderAssetsItem(asset)], 'id')
              : getSelectedAssets()

            if (isAlreadySelected) {
              updatedSelectedAssets = updatedSelectedAssets.filter((i) => i.id !== item.id)
            }
            setSelectedAssets(updatedSelectedAssets)
            mapDrawer.renderSelectedAssetProximity(updatedSelectedAssets)
          } else {
            onAssetClick(item.id as number)
          }
        },
      })
    }
  }, [mapDrawer, mapDrawer.hasMap(), assetsData, onAssetClick])

  useEffect(() => {
    if (!isEmpty(incidentsData)) {
      // README: Temporary rendering via demo controls
      // const incidents = hasFilters ? filterIncidents(incidentsData!, filters) : incidentsData
      // renderIncidents(incidents)
    }
  }, [mapDrawer, incidentsData, hasFilters, filters, renderIncidents])

  useEffect(() => {
    if (!activeTarget) {
      mapDrawer.resetActiveTargets()
    }
  }, [mapDrawer, activeTarget])

  return (
    <MapEventsContext.Provider
      value={{
        activeTarget,
        incidentsData: incidentsData || [],
        incidentTypesData: incidentTypesData || [],
        isLoadingData: isFetchingPureIncidents || isFetchingQueryIncidents,
        setActiveTarget,
        activeIncidents,
        setActiveIncidents,
        renderIncidents,
      }}
    >
      {children}
    </MapEventsContext.Provider>
  )
}
