import { AssetItemDB, IncidentItemDB, IncidentTypeItemDB, SeverityItemDB } from './BigQueryEventsClient.types.db'

export type { OrgParams } from './GoogleBigQueryClient.types'

export enum TargetType {
  Asset = 'Asset',
  Incident = 'Incident',
}

type Lat = number
type Lng = number
export type CoordBounds = [[Lat, Lat], [Lng, Lng]]

export interface IncidentFilterOptions {
  ids?: IncidentItemDB['IncidentID'][]
  coordBounds?: CoordBounds
  incidentTypeID?: IncidentItemDB['IncidentTypeID'][]
  severityLevel?: IncidentItemDB['SeverityLevel'][]
}

export interface LocationTarget {
  _targetType: TargetType
}

type Timestamp = string

export interface AssetItem extends AssetItemDB, LocationTarget {}

export interface IncidentItem
  extends Pick<
      IncidentItemDB,
      | 'IncidentID'
      | 'City'
      | 'Region'
      | 'Address'
      | 'Country'
      | 'IncidentDescription'
      | 'IncidentTypeID'
      | 'IncidentTypeName'
      | 'Latitude'
      | 'Longitude'
      | 'Title'
      | 'DataSource'
      | 'Hyperlink'
      | 'SeverityLevel'
    >,
    LocationTarget {
  IncidentDateTime: Timestamp
}

export interface IncidentFeedItem
  extends Pick<
    IncidentItem,
    | 'IncidentID'
    | 'City'
    | 'Address'
    | 'IncidentDateTime'
    | 'IncidentDescription'
    | 'IncidentTypeID'
    | 'IncidentTypeName'
    | 'Latitude'
    | 'Longitude'
    | 'Title'
    | 'DataSource'
    | 'Hyperlink'
    | '_targetType'
  > {}

export interface IncidentTypeItem extends IncidentTypeItemDB {}

export interface SeverityItem extends SeverityItemDB {}
